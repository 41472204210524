export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"mobile-web-app-capable","content":"yes","key":"mobile-web-app-capable"},{"name":"apple-mobile-web-app-title","content":"Kangoeroe","key":"apple-mobile-web-app-title"},{"name":"theme-color","content":"#97C23D","key":"theme-color"},{"property":"og:type","content":"website","key":"og:type"},{"property":"og:title","content":"Kangoeroe","key":"og:title"},{"property":"og:site_name","content":"kangoeroe-app","key":"og:site_name"},{"name":"twitter:card","content":"summary","key":"twitter:card"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png"},{"rel":"icon","href":"/_nuxt/icons/64x64.5d7d051b.png","key":"favicon"},{"rel":"apple-touch-icon","href":"/_nuxt/icons/512x512.maskable.5d7d051b.png","sizes":"512x512","key":"favicon-apple"},{"rel":"manifest","href":"/manifest.json"},{"rel":"stylesheet","type":"text/css","href":"https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"}],"style":[],"script":[],"noscript":[],"title":"Kangoeroe","description":"Met de Kangoeroe app kan je digitaal deelnemen aan onze wedstrijden.","charset":"utf-8","viewport":"width=device-width, initial-scale=1","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'